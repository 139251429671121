function formDenial(formInputs){
    for (const input of formInputs){
        if (input.value == ""){
            input.ref.current.scrollIntoView({behavior: "smooth"});
            input.ref.current.focus();
            input.func(true);
            return
        }
    }
    return
}

export default formDenial;