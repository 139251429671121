
import { styled, keyframes } from "styled-components"
import useNavigateToTop from "../hooks/useNavigateToTop";

function NavbarLink({page, currentLink, nav}){

    const Link = styled.span`
    font-size: 1.2rem;
    color: ${props => props.nav? "#7b7979" : "#FFFFFF"};
    font-weight: 100px;
    text-decoration: none;
    &:hover{
        color: ${props => props.nav? "#086872" : "#f4f3f3"};
        cursor: pointer;
    }
    `
    const SelectedLink = styled.span`
    font-size: ${props => props.nav? "1.5rem" : "1.2rem"};
    text-decoration: none;
    font-weight: bold;
    color: ${props => props.nav? "#086872" : "#e5e5e5"};
    &:hover{
        cursor: pointer;
    }
    `
    const handler = useNavigateToTop(page.link);
    return(
        <>{(page.link === currentLink)?
            <SelectedLink onClick={handler} nav={nav}>{page.name}</SelectedLink> :
            <Link onClick={handler} nav={nav}>{page.name}</Link>}
        </>)
}

export default NavbarLink;