import { useNavigate } from "react-router-dom";

const useNavigateToTop = (url) => {
    const navigate = useNavigate();
    
    const navigateToTop = () => {
        navigate(url);
        window.scrollTo(0, 0);
    }
    
    return navigateToTop;
}

export default useNavigateToTop;