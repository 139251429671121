import styled from "styled-components";
import PositionList from "../components/PositionList";
import {collection, getDoc, setDoc, doc, getDocs} from "firebase/firestore";
import {db} from "../lib/firebase";
import {useEffect, useState} from "react";


function CareerPage(){
    return(
        <div>
            <Div>
                <h1>Your Future Begins Here.</h1>
                <h3>Have you been searching for a job with all the right features — great work environment, flexible opportunities, and an amazingly dynamic and diverse team and culture? Look no further.</h3>
                <p>Roga Partners has a wide range of open positions, located in our upscale Har Hotzvim, Jerusalem office, each with different hours and disparate levels of prior training and experience. Check out the openings below to start your journey as a valued member of one of our dynamic and expanding teams!</p>
                <h3>Ready to make an impact? So are we.</h3>
                <h2>Positions Currently Available</h2>
            </Div>
            <PositionList/>
            <GreyH>To apply to positions, you can also send your resume to talent@rogapartners.com</GreyH>
        </div>
    )
}

const Div = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
color: #086872;
width: 95%;
margin: 0 auto;
& h1{
    font-size: 3rem;
}
& > *{
    margin-bottom: 1rem;
}
& h3{
    font-size: 1rem;
}
`

const GreyH = styled.h4`
color: #555353;
padding-top: 2rem;
margin: 0 auto;
text-align: center;
margin-bottom: 2rem;
`


export default CareerPage;

