import React from 'react';

import styled from 'styled-components';
import Button from '../../elements/Button'
import background1 from '../../assets/bg1.svg';
import Services from '../../components/Services/Services';
import Steps from '../../components/Steps/Steps';
import Clients from '../../components/Clients/Clients';
import TextWithBackground from '../../components/TextWithBackground';
import homepage from '../../assets/homepage.svg'
import useNavigateToTop from '../../hooks/useNavigateToTop';

export default function Home() {
  return (
    <div className='Home'>
      <TextWithBackground background={background1} content={
        <ContentDiv>
          <p>
            Unlock the <b>power of your data</b> today.<br></br>
            It's that simple.
          </p>
          <Button large onClick={useNavigateToTop('/consultation')}>
            Contact Us
          </Button>
        </ContentDiv>
        } src={homepage}/>
      <Services />
      <Steps />
      {/* <Clients /> */}
    </div>
  )
}

const ContentDiv = styled.div`
  padding: 3rem 0 6rem 0;
  & p{
    font-size: 1.5rem;
    color: #086872;
    font-weight: bold;
    margin-bottom: 1rem;
    @media (min-width: 720px){
      font-size: 3rem;
    }
  }
  & h1{
    color: white;
  }
  & span{
    color: white;
    margin-bottom: 1rem;
  }
  & button{
    margin-bottom: 2rem;
  }
`
