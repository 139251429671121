import {object, string, number, setLocale} from 'yup';

setLocale(
    {string: {
        matches: "Phone must match a valid phone number including country code"
    }}
)

const applicationSchema = object({
    name: string().required("Name is required"),
    email: string().email("Email must be valid").required("Email is required"),
    phone: string().matches(/[0-9]{1,3}[0-9]{8,11}/).required(),
    hours: string().required("Hours is required"),
    cv: string().required("CV is required"),
    coverLetter: string()
})

export default applicationSchema;