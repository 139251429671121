import { useState, useRef } from "react";
import styled from "styled-components";


function DragDrop({handler, file}){
    const [isDragActive, setIsDragActive] = useState(false)
    const inputRef = useRef(null)

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setIsDragActive(true);
          } else if (e.type === "dragleave") {
            setIsDragActive(false);
        }
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragActive(false)
        if(e.dataTransfer.files && e.dataTransfer.files.length > 0 && e.dataTransfer.files[0].type === "application/pdf"){
            handler(e.dataTransfer.files[0])
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        if(e.target.files && e.target.files[0]){
            handler(e.target.files[0])
        }
    }

    const onButtonClick = () => {
        inputRef.current.click()
    }

    return(
        <DragDropForm 
            onDragEnter={handleDrag}
            onSubmit={(e) => {e.preventDefault()}}
        >
            <input accept=".pdf" type="file" id="input-file-upload" ref={inputRef} onChange={handleChange}/>
            <label 
                htmlFor="input-file-upload"
                style={{
                    opacity: isDragActive ? 0.1 : 1
                }}
            >
                <div>
                    <p>Drag and drop your file here or</p>
                    <button onClick={onButtonClick}>Upload a file</button>
                    <p style={{fontSize:"0.7rem", color:"gray", marginTop:"1rem"}}>{file ? file.name : "Please upload PDF files only"}</p>
                </div> 
            </label>
            { isDragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}><span>Drop Files Here</span></div> }
        </DragDropForm>
    )
}

export default DragDrop;

const DragDropForm = styled.form`
    text-align: center;
    width: 90vw;
    height: 65vw;
    position: relative;
    @media (min-width: 720px){
        width: 40vw;
        height: 20vw;
        margin-left: 30vw;
    }
    & input{
        display: none;
    }
    & label{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #cbd5e1;
        border-radius: 5px;
        background-color: #f8fafc;
    }
    & label:hover{
        border: 2px solid #086872;
        cursor: pointer;
    }

    & button{
        cursor: pointer;
        padding: 0.25rem;
        font-size: 1rem;
        border: none;
        background-color: transparent;
    }
    & button:hover{
        text-decoration: underline;
    }
    & #drag-file-element{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(255,255,255,.8);
        display: flex;
        align-items: center;
        justify-content: center;
        & span{
            margin: 0;
            align-self: center;
        }
    }
`