import { styled } from "styled-components";

const Button = styled.button`
background-color: ${props => props.primary? "#086872" : "#FFFFFF"};
border-radius: 25px;
border: 2px solid #086872;
color: ${props => props.primary? "#FFFFFF" : "#086872"};
box-shadow: 0 0 2px teal ;
padding: 0.7rem 0.6rem 0.7rem 0.6rem;
font-size: ${props => props.large? "1.2rem" : "0.6rem"};
font-weight: ${props => props.nav? "bolder" : "regular"};
&:hover{
    background-color: ${props => props.primary? "#FFFFFF" : "#086872"};
    color: ${props => props.primary? "#086872" : "#FFFFFF"};
    cursor: pointer;
}
`

export default Button;