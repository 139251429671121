import { useState, useEffect } from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../lib/firebase";
import JobListing from "../elements/JobListing";
import LoadingSpinner from "../elements/LoadingSpinner";

import styled from "styled-components";

function PositionList(){
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const getJobs = async () => {
            setIsLoading(true);
            const jobsRef = collection(db, "jobs");
            const jobSnapshot = await getDocs(jobsRef);
            const jobList = jobSnapshot.docs.map((doc) => 
                {return({...doc.data(), id: doc.id})}
            );
            setIsLoading(false);
            setJobs(jobList);
        }
        getJobs();
    }, [])

    return (
        <div>
            <Div>
                <span>Job Title</span>
                <span>Department</span>
                <span>Hours</span>
                <div></div>
            </Div>
            <div style={{display:"flex", flexFlow:"row nowrap", justifyContent:"center"}}>
                {isLoading && <LoadingSpinner/>}
            </div>
            {
                jobs.map((job) => <JobListing job={job}/>)
            }
        </div>
    )
}

const Div = styled.div`
display: none;
flex-flow: row nowrap;
justify-content: space-between;
width: 90%;
margin: 0 auto;
border-bottom: 1px solid #086872;
padding-bottom: 1rem;
& span{
    flex: 1;
    color: #7b7979;
}
& div{
    flex: 1;
}
@media (min-width: 720px){
    display: flex;
}
`

export default PositionList;