import robot from '../../assets/services/robot-svgrepo-com.svg'
import dashboard from '../../assets/services/chart-line-solid.svg'
import cloud from '../../assets/services/cloud-solid.svg'
import brain from '../../assets/services/brain-solid.svg'
import handshake from '../../assets/services/handshake-angle-solid.svg'
import microscope from '../../assets/services/microscope-solid.svg'
import BIDashboard from '../../assets/services/dashboard-report-icon.svg'

const SERVICES = [
    {
        header: "BI Dashboards",
        text: "For your data visualization needs, Roga Analytics offers brilliant dashboards that display critical insights. Enhance decision-making and understand your business landscape like never before.",
        image: {
            src: BIDashboard,
            alt: "A computer with a BI dashboard"
        }
    },
    {
        header: "Data Analytics",
        text: "With our expertly trained teams, we ensure your business operates in harmony with the sharpest data insights. Our reporting and live dashboards put you ahead of the curve.",
        image: {
            src: dashboard,
            alt: "A chart with a line going up"
        }
    },
    {
        header: "Two-Way API Integrations",
        text: "Streamline your data across disparate platforms with our two-way API integrations. Our seamless synchronization services optimize efficiency and ensure smooth information flow.",
        image: {
            src: cloud,
            alt: "an icon of a cloud"
        }
    },
        {
        header: "Natural Language Processing (NLP) Models",
        text: "Our advanced NLP models transform unstructured data into insightful intelligence. Tap into new layers of understanding with our tailored language processing tools.",
        image: {
            src: brain,
            alt: "An icon of a brain"
        }
    },
    {
        header: "AI Integration",
        text: "Receive a custom solution designed to harness the transformative potential of artificial intelligence in your business. Our AI experts craft tools, algorithms, and models to revolutionize your operations.",
        image: {
            src: robot,
            alt: "A robot"
        }
    },
    {
        header: "Custom Algorithms",
        text: "Our custom algorithms analyze and predict, providing unparalleled insights that push your business to the next level. Embrace data-driven decision-making with confidence.",
        image: {
            src: microscope,
            alt: "a microscope"
        }
    },
    {
        header: "Consultation and Implementation Services",
        text: "Need strategic guidance? We offer consultation and implementation services to ensure seamless integration of AI into existing systems. We're with you at every step of the way.",
        image: {
            src: handshake,
            alt: "A handshake"
        }
    }
]

export default SERVICES;