function ErrorPage(){
    return(
        <div style={{display:"grid", placeItems:"center"}}>
            <h1 style={{fontSize:"7rem", color:"#086872"}}>404</h1>
            <h2>Oops!</h2>
            <p>It seems like this page doesn't exist.</p>
        </div>
    )
}

export default ErrorPage;