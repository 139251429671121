import { useState } from "react"

import './css/RevealingModal.css'
import { styled } from "styled-components"


function RevealingModal({button, content}){
    const [isShown, setIsShown] = useState(false)
    function toggleIsShown(){
        setIsShown(!isShown)
    }

    return(
        <div className="revealing-modal-div" style={{color: "#086872", alignSelf:"baseline"}}>
            <div style={{backgroundColor:isShown? "#d3d3d3": "white"}} className="modal-button-div" onClick={toggleIsShown}>
                {button}
            </div>
            <div className="modal-bg" style={{display: isShown? "block" : "none"}} onClick={toggleIsShown}>
                <div className="modal-content" style={{color: "black"}}>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default RevealingModal