import Button from "../elements/Button";
import RevealingModal from "../elements/RevealingModal";
import rogaLogo from '../assets/roga-logo.png'

import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

import NavbarLink from "../elements/NavbarLink";
import pages from "../lib/pageList"
import { AuthContext } from "../contexts/AuthContext";
import { useContext } from "react";
import { PAGES } from "../lib/constants/pages";
import useMediaQuery from "../hooks/useMediaQuery";
import useNavigateToTop from "../hooks/useNavigateToTop";

const LinksDiv = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
    @media (max-width: 720px){
        display: none;
    }
`

const Nav = styled.nav`
    position: sticky;
    top: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    margin: 0 auto;
    margin-top: 3vh;
    align-items: center;
    height: 15vh;
    background-color: white;
    z-index: 100;
`


function Navbar(){
    const isDesktop = useMediaQuery('(min-width: 720px)')
    const currentLink = window.location.pathname;
    const { user, logout }  = useContext(AuthContext);
    const navlinks = pages.map((page) => {
        return(
            <NavbarLink page={page} currentLink={currentLink} nav={true}/>
        )
    })

    return(
        <Nav>
            <div style={{display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"space-between"}}>
                <RevealingModal
                    button={<FontAwesomeIcon icon={faBars} size="2x"/>}
                    content={
                        <>
                        {navlinks}
                        {user && 
                            (user.data?.role == 'admin' ? 
                            <NavbarLink page={{name: "Admin", link: PAGES.admin}} currentLink={currentLink} nav={true}/>
                            :
                            <NavbarLink page={{name: "Portal", link: PAGES.user}} currentLink={currentLink} nav={true}/>)
                        }
                        {user && 
                            <NavbarChip onClick={logout}>Log out</NavbarChip>
                        }
                        {!user &&
                            <NavbarLink page={{name: "Login", link: PAGES.login}} currentLink={currentLink} nav={true}/>
                        }
                        </>
                    }
                />
                <a href="/">
                    <img src={rogaLogo} alt="Roga Analytics Logo, go to homepage" style={{height: "4rem"}}/>
                </a>
            </div>
            <LinksDiv>
                {navlinks}
                {user && 
                    (user.data?.role == 'admin' ? 
                        <NavbarLink page={{name: "Admin", link: PAGES.admin}} currentLink={currentLink} nav={true}/>
                    :
                        <NavbarLink page={{name: "Portal", link: PAGES.user}} currentLink={currentLink} nav={true}/>)
                }
                {user && 
                    <NavbarChip onClick={logout}>Log out</NavbarChip>
                }
                {!user &&
                    <NavbarLink page={{name: "Login", link: PAGES.login}} currentLink={currentLink} nav={true}/>
                }
                </LinksDiv>
            <Button primary nav onClick={useNavigateToTop('/consultation')} style={{width: isDesktop ? "15%" : ""}}>
                Book a Free Consultation
            </Button>
        </Nav>
    )
}

const NavbarChip = styled.span`
    font-size: 1.2rem;
    color: #7b7979;
    font-weight: 100px;
    text-decoration: none;
    &:hover {
        color: #086872;
        cursor: pointer;
    }
`;

export default Navbar;