import { styled, keyframes } from "styled-components";

function LoadingSpinner() {
  return (
    <Spinner/>
  );
}

const ldsDualRing = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const Spinner = styled.div`
    display: inline-block;
    width: 80px;
    height: 80px;
    align-self: center;

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #086872;
        border-color: #086872 transparent #086872 transparent;
        animation: ${ldsDualRing} 1.2s linear infinite;
    }
`

export default LoadingSpinner;