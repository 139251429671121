
import { styled } from "styled-components"
import useNavigateToTop from "../hooks/useNavigateToTop";

function JobListing({job}){
    return(
        <Div>
            <span style={{fontWeight:"bolder"}}>{job.title}</span>
            <span>{job.department}</span>
            <span>{job.hours}</span>
            <div>
                <button onClick={useNavigateToTop(`/job/${job.id}`)}>View Job</button>
            </div>
        </Div>
    )
}

const Div = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
color: #086872;
border-bottom: 1px solid #086872;
padding-bottom: 1rem;
padding-top: 1rem;
width: 90%;
margin: 0 auto;
& button{
    background-color: #086872;
    border: 1px solid #086872;
    color: white;
    border-radius: 5px;
}
@media (min-width: 720px){
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    & span{
        flex: 1;
    }
    & button{
        font-size: 1.2rem;
        cursor: pointer;
        border: 2px solid #086872;
        &:hover{
            background-color: #FFFFFF;
            color: #086872;
            border: 2px solid #086872;
        }
    }
    & div{
        flex: 1;
        display: flex;
        justify-content: space-evenly;
    }
}
`


export default JobListing;