import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import {useNavigate} from 'react-router-dom'

import styled from 'styled-components';

export default function Service({ title, icon, link}) {
  const navigate = useNavigate()
  return (
    <SERVICE>
      <FontAwesomeIcon className='icon' icon={icon} />
      <span className='service-title'>{title}</span>
      <a href="" className='link' onClick={() => navigate(link)}>
        <span>Learn More</span>
        <span
          className='fa-solid fa-arrow-right'
          aria-hidden={true}
        />
      </a>
    </SERVICE>
  )
}

const SERVICE = styled.li`
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 7px 0px;
  border-radius: 2rem;
  border: 2px solid var(--main-color);
  width: 16.25rem;
  height: 11.25rem;

  padding: 0 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 0.625rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  .icon {
    font-size: 2.75rem;
    color: var(--main-color);
  }

  .service-title {
    font-size: 1.125rem;
  }

  .link {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;

    font-size: 0.875rem;
    color: var(--light-text-color);

    text-decoration: none;

    transition: 0.3s ease-in-out;
  }

  .link:hover {
    transform: scale(1.2);
  }

  @media (min-width: 720px) {
    width: 20vw;
    max-width: 16.5rem;
    height: 16.8rem;

    .icon {
      font-size: 4rem;
    }

    .service-title {
      font-size: 1.25rem;
    }
  }
`;
