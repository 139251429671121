import { useState } from "react"
import Button from "../elements/Button"
import styled from "styled-components"
import { db } from "../firebase"
import { addDoc, collection, doc, setDoc } from "firebase/firestore"


function AddJob(){
    const [title, setTitle] = useState("")
    const [location, setLocation] = useState("")
    const [hours, setHours] = useState("")
    const [department, setDepartment] = useState("")
    const [description, setDescription] = useState("")
    const [isMessage, setIsMessage] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const addJobHandler = async () => {
        try{
            setIsError(false)
            setIsMessage(false)
            await addDoc(collection(db, "jobs"), {
                title, location, hours, department, description
            })
            setIsMessage(true)
            setTitle("")
            setLocation("")
            setHours("")
            setDepartment("")
            setDescription("")
        }catch(err){
            setIsMessage(true)
            setIsError(true)
            setErrorMessage(err.message)
        }
    }

    return(
        <FormDiv>
            <h1 style={{alignSelf:"center", marginTop:"2rem"}}>Add Job</h1>
            <span>Job Title</span>
            <input type="text" value={title} onChange={(e) => {setTitle(e.target.value)}}/>
            <span>Location</span>
            <input type="text" value={location} onChange={(e) => {setLocation(e.target.value)}}/>
            <span>Hours</span>
            <input type="text" value={hours} onChange={(e) => {setHours(e.target.value)}}/>
            <span>Department</span>
            <input type="text" value={department} onChange={(e) => {setDepartment(e.target.value)}} />
            <span>Description</span>
            <textarea value={description} cols="50" rows="6" onChange={(e) => setDescription(e.target.value)}></textarea>
            <Button primary large onClick={addJobHandler}>Add Job</Button>
            {isMessage && <p>{isError ? errorMessage : `Job added`}</p>}
        </FormDiv>
    )
}

const FormDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    @media (min-width: 720px){
        align-items: flex-start;
    }
    & textarea{
        resize: none;
        width: 90vw;
        height: 30vh;
        border: 1px solid #086872;
        font-family: "Poppins", sans-serif;
        border-radius: 5px;
        @media (min-width: 720px){
            width: 40vw;
            margin-left: 30vw;
        }
    }
    & textarea:focus{
        border: 2px solid #086872;
        outline: none;
    }
    & input{
        font-family: "Poppins", sans-serif;
        padding: 0.5rem;
        border: 1px solid #086872;
        border-radius: 5px;
        width: 90vw;
        @media (min-width: 720px){
            width: 40vw;
            margin-left: 30vw;
        }
    }
    & input:focus{
        border: 2px solid #086872;
        outline: none;
    }

    & span{
        margin-top: 2rem;
        align-self: flex-start;
        color: #086872;
        margin-left: 5vw;
        @media (min-width: 720px){
            margin-left: 30vw;
        }
    }

    & select{
        font-family: "Poppins", sans-serif;
        padding: 0.5rem;
        border: 1px solid #086872;
        border-radius: 5px;
        color: #086872;
        align-self: flex-start;
        margin-left: 5vw;
        @media (min-width: 720px){
            margin-left: 30vw;
        }
    }

    & button{
        align-self: center;
        margin-top: 2rem;
    }
`

export default AddJob