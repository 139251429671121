import { styled } from "styled-components";


function TextWithImage({text, image, header, inverted}){
    const ImageTop = styled.img`
    width: 80%;
    @media (min-width: 720px){
        width: 15%;
    }
    `

    const Div = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    color: #086872;
    text-align: justify;
    width: 90vw;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3vh;
    @media (min-width: 720px){
        width: 90vw;
        flex-flow: row;
        justify-content: space-between;
    }
    `
    const TextDiv = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    & h1{
        border-bottom: 2px solid #086872;
    }
    @media (min-width: 720px){
        padding-left: 4rem;
        align-items: flex-start;
        & h1{
            font-size: 2rem;
        }
        & p{
            font-size: 1.2rem;
        }
    }
    & > *{
        margin: 1rem 0 0 0;
    }
    `
    return(
        <Div>
            <ImageTop src={image.src} alt={image.alt}/>
            <TextDiv>
                <h1>{header}</h1>
                <p>{text}</p>
            </TextDiv>
        </Div>
    )
}

export default TextWithImage;