import { useEffect, useState } from "react"
import AccordionLink from "../elements/AccordionLink"
import { collection, getDocs, deleteDoc, doc } from "@firebase/firestore";
import { db } from "../firebase";
import Button from "../elements/Button";



function Joblist(){
    const [jobs, setJobs] = useState([])
    const [applications, setApplications] = useState([])
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        const getJobs = async () => {
            const jobsRef = collection(db, "jobs");
            const jobSnapshot = await getDocs(jobsRef);
            const jobList = jobSnapshot.docs.map((doc) => 
                {return({...doc.data(), id: doc.id})}
            );
            setJobs(jobList);
            const appsRef = collection(db, "applications");
            const appSnapshot = await getDocs(appsRef);
            const appList = appSnapshot.docs.map((doc) =>{
                return({...doc.data(), id: doc.id})
            })
            setApplications(appList);
        }
        getJobs();
    }, [refresh])

    const deleteJob = async (id) => {
        await deleteDoc(doc(db, "jobs", id));
        applications.forEach(async app =>{
            if (app.JobId === id){
                await deleteApplication(app.id);
            }
        })
    }

    const deleteApplication = async (id) => {
        await deleteDoc(doc(db, "applications", id));
        setRefresh(!refresh);
    }

    return(
        <div style={{marginTop:"2rem"}}>
            {jobs.map(job => {
                return(
                    <AccordionLink title={job.title} children={
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{fontWeight:"bolder"}}>Description</span>
                            <span>{job.description}</span>
                            <span style={{fontWeight:"bolder"}}>Department</span>
                            <span>{job.department}</span>
                            <span style={{fontWeight:"bolder"}}>Hours</span>
                            <span>{job.hours}</span>
                            <span style={{fontWeight:"bolder"}}>Location</span>
                            <span>{job.location}</span>
                            <Button primary onClick={() => {deleteJob(job.id)}} style={{width:"50%"}}>Delete Job</Button>
                            <span style={{fontWeight:"bolder", fontSize:"2rem"}}>Applications</span>
                            {applications.map(app => {
                                if(app.jobId === job.id){
                                    return(
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <span style={{fontWeight:"bolder"}}>Name</span>
                                            <span>{app.name}</span>
                                            <span style={{fontWeight:"bolder"}}>Email</span>
                                            <span>{app.email}</span>
                                            <span style={{fontWeight:"bolder"}}>Phone</span>
                                            <span>{app.phone}</span>
                                            <span style={{fontWeight:"bolder"}}>CV</span>
                                            <a href={app.cv} target="_blank">Click to download</a>
                                            <span style={{fontWeight:"bolder"}}>Cover Letter</span>
                                            <span>{app.coverLetter}</span>
                                            <Button primary onClick={() => {deleteApplication(app.id)}} style={{width:"50%"}}>Delete Application</Button>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    }/>
                )
            })}
        </div>
    )
}

export default Joblist;