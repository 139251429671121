import React from 'react';

import styled from 'styled-components';

export default function SectionTitle({
  title,
  align='center',
  fontColor='var(--dark-text-color)',
  fontSize='m'
}) {
  return (
      <SECTION_TITLE
        $align={align}
        $fontColor={fontColor}
      >
        <h3 className={fontSize}>{title}</h3>
        <span className='line' />
      </SECTION_TITLE>
  )
}

const SECTION_TITLE = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${props => props.$align};
  gap: 0.5rem;
  margin-bottom: 2rem;
  margin-top: 13rem;
  @media (min-width: 720px) {
    margin-top: 5rem;
  }

  h3 {
    font-weight: 600;
    color: ${props => props.$fontColor};
  }

  h3.m {
    font-size: 1.625rem;
  }

  h3.l {
    font-size: 1.875rem;
  }

  h3.xl {
    font-size: 2rem;
  }

  h3.xxl {
    font-size: 2.25rem;
  }

  .line {
    width: 14.5rem;
    height: 0.25rem;
    background-color: var(--main-color);
  }

  @media (min-width: 720px) {
    h3.m, h3.l {
      font-size: 2.25rem;
    }

    h3.xl, h3.xxl {
      font-size: 2.563rem;
    }
  }
`;