export const CLIENT_IMAGES = [
  {
    alt: 'solanys',
    src: require('../../assets/clients/solanys.png')
  },
  {
    alt: 'aria-legacy',
    src: require('../../assets/clients/aria-legacy.png')
  },
  {
    alt: 'ezra',
    src: require('../../assets/clients/ezra.png')
  },
  {
    alt: 'samba',
    src: require('../../assets/clients/samba.png')
  },
  {
    alt: 'guardian',
    src: require('../../assets/clients/guardian.jpeg')
  },
  {
    alt: 'marquis',
    src: require('../../assets/clients/marquis.png')
  },
  {
    alt: 'yeled-ve-yalda',
    src: require('../../assets/clients/yeled-ve-yalda.png')
  },
  {
    alt: 'torah-mitzion',
    src: require('../../assets/clients/torah-mitzion.png')
  }
];