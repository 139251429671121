import { useState } from "react"
import { styled } from "styled-components"
import { getAuth, createUserWithEmailAndPassword, signOut } from "firebase/auth"
import { generatePassword } from "../lib/utils/passwordGenerator"
import { db } from "../lib/firebase"

import Button from '../elements/Button'
import { setDoc, doc } from "firebase/firestore"
import { secondaryApp, secondaryAuth } from "../firebase.js"

function AddUser(){

    const [displayName, setDisplayName] = useState("")
    const [email, setEmail] = useState("")
    const [dashboards, setDashboards] = useState([])
    const [dashName, setDashName] = useState("")
    const [datasetId, setDatasetId] = useState("")
    const [reportId, setReportId] = useState("")
    const [password, setPassword] = useState("")
    const [isMessage, setIsMessage] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const dashHandler = () => {
        const newDash = {
            name: dashName,
            datasetId,
            reportId
        }
        setDashboards([...dashboards, newDash])
        setDashName("")
        setDatasetId("")
        setReportId("")
    }

    const addUserHandler = async () => {
        try{
            setIsMessage(false)
            setIsError(false)
            const password = generatePassword(20)
            const user = await createUserWithEmailAndPassword(secondaryAuth, email, password)
            await signOut(secondaryAuth)
            setPassword(password)
            const id = user.user.uid
            await setDoc(doc(db, "users", id), {
                displayName, dashboards, id
            })
            setIsMessage(true)
        }catch(err){
            setIsMessage(true)
            setIsError(true)
            setErrorMessage(err.message)
        }
    }


    return(
        <FormDiv>
            <h1 style={{alignSelf:"center", marginTop:"2rem"}}>Add User</h1>
            <span>Client Name</span>
            <input type="text" value={displayName} onChange={(e) => {setDisplayName(e.target.value)}}/>
            <span>Email</span>
            <input type="email" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
            <span>Dashboards</span>
            <p>{dashboards.map((dashboard) => dashboard.name + ", ")}</p>
            <h2>Add Dashboard</h2>
            <span>Dashboard Name</span>
            <input type="text" value={dashName} onChange={(e) => {setDashName(e.target.value)}} />
            <span>Dataset ID</span>
            <input type="text" value={datasetId} onChange={(e) => {setDatasetId(e.target.value)}}/>
            <span>Report ID</span>
            <input type="text" value={reportId} onChange={(e) => {setReportId(e.target.value)}}/>
            <div>
                <Button primary onClick={dashHandler}>Add Dashboard</Button>
                <Button primary onClick={() => {setDashboards([])}}>Remove Dashboards</Button>
            </div>
            <Button primary large onClick={addUserHandler}>Add User</Button>
            {isMessage && <p>{isError ? errorMessage : `User Added, password is ${password}`}</p>}
        </FormDiv>
    )
}

const FormDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    @media (min-width: 720px){
        align-items: flex-start;
    }
    & input{
        font-family: "Poppins", sans-serif;
        padding: 0.5rem;
        border: 1px solid #086872;
        border-radius: 5px;
        width: 90vw;
        @media (min-width: 720px){
            width: 40vw;
            margin-left: 30vw;
        }
    }
    & input:focus{
        border: 2px solid #086872;
        outline: none;
    }
    & span{
        margin-top: 2rem;
        align-self: flex-start;
        color: #086872;
        margin-left: 5vw;
        @media (min-width: 720px){
            margin-left: 30vw;
        }
    }
    & h2{
        margin-top: 2rem;
        align-self: flex-start;
        margin-left: 5vw;
        @media (min-width: 720px){
            margin-left: 30vw;
        }
    }
    & button{
        margin-top: 2rem;
        align-self: center;
    }
    & p{
        margin-left: 5vw;
        @media (min-width: 720px){
            margin-left: 30vw;
        }
    }
    & div{
        align-self: center;
        display: flex;
        flex-flow: row nowrap;
        width: 30vw;
        justify-content: space-between;
    }
`

export default AddUser;