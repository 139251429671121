import React from 'react'

import styled from 'styled-components';

import SectionTitle from '../../elements/SectionTitle';
import { CLIENT_IMAGES } from '../../lib/constants/clientImages';

export default function Clients() {
  return (
    <CLIENTS>
      <SectionTitle
        title='Our Partners'
        align='center'
        fontColor='var(--dark-text-color)'
        fontSize='0.5'
      />
      <ul>
        {CLIENT_IMAGES.map((image, index) => (
          <img 
            key={index}
            src={image.src}
            alt={image.alt}
          />
        ))}
      </ul>
    </CLIENTS>
  )
}

const CLIENTS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    padding: 0;
  }

  img {
    max-width: min(75%, 16rem);
    max-height: 12rem;
  }

  @media (min-width: 720px) {
    ul {
      max-width: 80%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 4rem;
    }

    img {
      min-width: 15%;
      max-width: 33%;
      max-height: 16.25rem;
    }
  }
`;
