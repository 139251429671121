import { useNavigate } from "react-router-dom";
import AddJob from "../components/AddJob";
import AddUser from "../components/AddUser";
import Joblist from "../components/Joblist";
import UserList from "../components/UserList";
import { AuthContext } from "../contexts/AuthContext";
import Button from "../elements/Button";
import { useContext, useState } from "react";
import { PAGES } from "../lib/constants/pages";


function AdminPage(){
    const [isAddJob, setIsAddJob] = useState(true);
    const [isAddUser, setIsAddUser] = useState(false);
    const [isJobList, setIsJobList] = useState(false);
    const [isUserList, setIsUserList] = useState(false);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!user) {
        navigate(PAGES.login);
    }
    if (user && user.data.role !== 'admin') {
        navigate(PAGES.user);
    }

    const clearAll = () => {
        setIsAddJob(false);
        setIsAddUser(false);
        setIsJobList(false);
        setIsUserList(false);
    }

    return(
        <div>
            {user && user.data.role === 'admin' && <>
            <div style={{display:"flex", flexFlow:"row nowrap", justifyContent:"space-evenly"}}>
                <Button large onClick={() => {
                    clearAll();
                    setIsAddJob(true);
                }}>Add Job</Button>
                <Button large onClick={() => {
                    clearAll();
                    setIsAddUser(true);
                }}>Add User</Button>
                <Button large onClick={() => {
                    clearAll();
                    setIsUserList(true);
                }}>User List</Button>
                <Button large onClick={() => {
                    clearAll();
                    setIsJobList(true);
                }}>Job List</Button>
            </div>
            {isAddJob && <AddJob/>}
            {isAddUser && <AddUser/>}
            {isUserList && <UserList/>}
            {isJobList && <Joblist/>}
            </>}
        </div>
    )
}

export default AdminPage;