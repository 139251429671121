import footerImg from '../assets/footer-bg.png'
import rogaWhite from '../assets/roga-white.png'
import styled from 'styled-components'
import Button from '../elements/Button'
import pages from '../lib/pageList'
import NavbarLink from '../elements/NavbarLink'
import useNavigateToTop from '../hooks/useNavigateToTop'


const MainDiv = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    background: linear-gradient(to top, #086872 0%, #086872 95%, white 95%, white 100%);
`

const ContentDiv = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: #086872;
    margin: 0 auto;
    width: 100%;
    border-top: 1px solid #086872;
    padding-bottom: 1.5rem;
    & h1{
        background-color: #086872;
        text-align: center;
        color: white;
        border-bottom: 2px solid white;
        padding-bottom: 1rem;
        font-size: 1.5rem;
        width: 80%;
    }
`

const A = styled.a`
font-size: 0.8rem;
text-align: center;
color: white;
font-weight: 100;
text-decoration: none;
`

const Grid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
align-items: center;
& a{
    font-size: 0.8rem;
    text-align: center;
    color: white;
    font-weight: 100;
}
@media (min-width: 720px){
    display: flex;
    flex-flow: row nowrap;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}
`

const DisappearingDiv = styled.div`
display: none;
@media (min-width: 720px){
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
    gap: 2rem;
}
`

const Img = styled.img`
width: 15vw;
@media (min-width: 720px){
    width: 70px;
}
`

function Footer(){

    const currentLink = window.location.pathname;
    const pagesToRender = pages.filter((page) => page.name !== "Portal Login")
    const navlinks = pagesToRender.map((page) => {
        return(
            <NavbarLink page={page} currentLink={currentLink}/>
        )
    })

    return(
        <MainDiv>
            <img src={footerImg} style={{width:"100%", borderBottom:"1px solid #086872"}}/>
            <ContentDiv>
                <h1>
                    Analytics with Integrity.
                </h1>
                <Button large primary style={{border: "1px solid white", marginTop: "1rem", marginBottom: "2rem"}} onClick={useNavigateToTop('/consultation')}>
                    Let's Get Started &#8594;
                </Button>
                <Grid>
                    <a href="/" style={{display: "flex", alignItems:"center", justifyContent:"space-around"}}>
                        <Img src={rogaWhite} alt="Logo of Roga Analytics with link to Home Page"/>
                    </a>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"5rem", alignItems:"center"}}>
                        <span style={{color:"white", fontWeight:"bold"}}>Contact us:</span>
                        <A href='tel:+ 1 (516) 234 - 6528'>
                            + 1 (516) 234 - 6528
                        </A>
                        <A href='mailto:info@rogapartners.com'>
                            info@rogapartners.com
                        </A>
                    </div>
                    <DisappearingDiv>
                        {navlinks}
                    </DisappearingDiv>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", gridColumn: "span 2", marginTop:"1rem", marginBottom:"1rem"}}>
                            <Button nav primary style={{border: "1px solid white"}} onClick={useNavigateToTop('/consultation')}>Book a Free Consultation</Button>
                    </div>
                </Grid>
            </ContentDiv>
        </MainDiv>
    )
}

export default Footer;