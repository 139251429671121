import React from 'react'

import styled from 'styled-components';
import SectionTitle from '../elements/SectionTitle';
import { PowerBIEmbed } from 'powerbi-client-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { models } from 'powerbi-client';
import { onFullScreen } from '../lib/utils/screen';

export default function Dashboard({name, reportId, powerBIToken}) {
  return (
    <DASHBOARD>
      <SectionTitle 
        title={name}
        align='center'
      />
      <div className='powerbi-wrapper'> 
        <div className='powerbi-container'>
          <PowerBIEmbed 
            embedConfig={{
              type: 'report',
              id: reportId,
              accessToken: powerBIToken,
              tokenType: models.TokenType.Embed,
              embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}`,
              settings: {
                panes: {
                  filters: {
                    expanded: false
                  }
                },
              },
            }}
          />
          <div className="powerbi-top" />
        </div>
        <FontAwesomeIcon
          className='icon expand-icon'
          icon={faExpand}
          onClick={(e) => onFullScreen(e.target.parentElement.querySelector('.powerbi-container'))}
        />  
      </div>
    </DASHBOARD>
  )
}

const DASHBOARD = styled.div`
  position: relative;

  width: 80%;
  height: 85vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  & .powerbi-wrapper {
    position: relative;

    height: 100%;
  }

  & .powerbi-wrapper div:nth-of-type(1) {
    height: 100%;
  }

  & .powerbi-wrapper .expand-icon {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    z-index: 1;

    font-size: 2.5rem;
    color: var(--main-color);
    cursor: pointer;

    transition: transform 0.2s, filter 0.2s;

    opacity: 0.7;
  }

  & .powerbi-wrapper .expand-icon:hover {
    transform: scale(1.1);
    filter: brightness(1.2);

    color: var(--main-color);
    opacity: 1;
  }

  & .powerbi-container {
    position: relative;
  }

  & .powerbi-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 42px;


    background-color: var(--main-color);
  }
`;
