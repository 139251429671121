import React from 'react'

import styled from 'styled-components';

import background3 from '../../assets/bg3.svg';
import SectionTitle from '../../elements/SectionTitle';
import Step from '../../elements/Step';
import { STEPS as steps } from '../../lib/constants/badges';

export default function Steps() {
  return (
    <STEPS>
      <SectionTitle
        title='How it Works'
        fontSize='xxl'
      />
      <ul>
        {steps.map((step, index) => <Step key={index} {...step} index={index + 1} />)}
      </ul>
    </STEPS>
  )
}

const STEPS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  background-image: url(${background3});
  min-height: 52vw;
  background-size: cover;

  text-align: center;

  @media (min-width: 720px) {
    padding: 0;
  }
  ul {
    padding: 0;
    padding-bottom: 8rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;

    list-style: none;
  }

  @media (min-width: 720px) {
    ul {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;